<template>
      <!-- ========== Dashboard Area end============= -->
      <div class="dashboard-area company-dashboard pt-120 mb-120">
        <div class="container">
            <div class="row">
                <company-menu />
                <div class="col-lg-12" id="scrollTarget">
                    <div class="table-wrapper2">
                        <div class="title-and-btn">
                            <div class="title">
                                <h4>Latest Job List:</h4>
                            </div>
                            <!-- <div class="job-post-btn">
                                <router-link to="/company/ad-list" class="primry-btn-2">Job Advertisement List</router-link>
                            </div> -->
                            <!-- <div class="job-post-btn">
                                <router-link to="/company/create-job" class="primry-btn-2">Create Job Post</router-link>
                            </div> -->
                            <div>
                                <router-link to="/company/create-job" class="primry-btn-2 lg-btn w-unset">Create Job Post</router-link>
                            </div>
                        </div>
                        <div class="table-filter-area mb-30">
                            <form>
                                <div class="form-wrap style-2 style-3">
                                    <div class="form-inner">
                                        <div class="input-area">
                                            <img src="assets/images/icon/search-2.svg" alt="">
                                            <input type="text" v-model="search_job_title" placeholder="Search by job title">
                                        </div>
                                        
                                    </div>
                                    <button class="primry-btn-1" @click.prevent="searchByTitle">Search</button>
                                </div>

                                <div class="form-wrap style-3">
                                    <div class="form-inner">
                                        <div class="input-area">
                                            <img src="assets/images/icon/calender2.svg" alt="">
                                            <!-- <input type="text" id="datepicker11" placeholder="Search by date"> -->
                                            <Calendar v-model="JobsDate" dateFormat="dd/mm/yy" @date-select="searchByDate" placeholder="Search by date" />
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="form-wrap">
                                    <div class="form-inner">
                                        <p>Show er page</p>
                                        <div class="select-area">
                                            <select class="select1">
                                                <option>05</option>
                                                <option>10</option>
                                                <option>15</option>
                                                <option>20</option>
                                              </select>
                                        </div>
                                    </div>
                                </div> -->
                            </form>
                            <div v-if="showAlert" class="alert alert-warning mt-3" role="alert">
                                        Enter Job Title to Search

                         
                            </div>
                        </div>
                        <table class="eg-table table job-list-table mb-0">
                            <thead>
                                <tr>
                                    <th>Job Title</th>
                                    <th>Applications</th>
                                    <th>Matched</th>
                                    <!-- <th>Shortlisted</th> -->
                                    <th>Viewed</th>
                                    <th>Not Viewed</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-if="isLoading">
                                    <td colspan="6">
                                        <ProgressSpinner  />
                                    </td>
                                </tr>
                                <tr v-else v-for="job in jobs" :key="job.id">
                                    <td data-label="Job Title">
                                        <div class="job-content">
                                            <h5>{{ job.job_title }}</h5>
                                            <p><img src="assets/images/icon/calender2.svg" alt=""><span>Deadline:</span> {{ (job.expiration) ? job.expiration : 'No Deadline' }}</p>
                                            <!-- <div v-bind:job-status="job.status"  class="form-check form-switch"  @click="handleSwitchChange(index)">
                                                <input class="form-check-input" :id="job.id" type="checkbox" :checked="job.status == 'active' ? true: false" id="flexSwitchCheckDefault1"  >
                                               
                                  
                                            </div> -->
                                            <InputSwitch v-model="job.jobStatus" :modelValue="switchValue"   @update:modelValue="handleSwitchChange(job)" />
                                            <!-- <InputSwitch v-if="jobStatusMap[job.id]" v-model="job.status"   @update:modelValue="handleSwitchChange(job)" />
                                            <InputSwitch v-else v-model="jobStatusMap[job.id]" 
                                            @update:modelValue="handleSwitchChange(job)" />  -->
                                           
                                                <!-- <InputSwitch v-model="job.status"  @update:modelValue="handleSwitchChange(job)" /> -->
                                                Live

                                                <!-- <InputSwitch id="toggleSwitch" modelValue="yes" /> -->
                                        </div>
                                    </td>
                                    <td data-label="Applications">
                                        <span class="total-number">{{ job.applications_count ?? 0 }}</span>
                                    </td>
                                    <td data-label="Matched">
                                        <span class="total-number light-orange">0</span>
                                    </td>
                                    <!-- <td data-label="Shortlisted">
                                        <span class="total-number">700</span>
                                    </td> -->
                                    <td data-label="Viewed">
                                        <span class="total-number light-yellow">{{ job.view_jobs_count ?? 0 }}</span>
                                    </td>
                                    <td data-label="Not Viewed">
                                        <span class="total-number deep-pink">0</span>
                                    </td>
                                    <td data-label="Action">
                                        <ul class="action-btn-group2">
                                            <li>
                                                <router-link :to="getJobDetail(job.job_key, job.job_slug)">
                                                    <svg width="14" height="10" viewBox="0 0 14 10" xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M14 5C14 5 11.375 0 7 0C2.625 0 0 5 0 5C0 5 2.625 10 7 10C11.375 10 14 5 14 5ZM1.02637 5C1.44945 4.33193 1.93606 3.70971 2.47887 3.14273C3.605 1.97091 5.145 0.909091 7 0.909091C8.855 0.909091 10.3941 1.97091 11.522 3.14273C12.0648 3.70971 12.5514 4.33193 12.9745 5C12.9237 5.07909 12.8678 5.16636 12.8039 5.26182C12.5108 5.69818 12.0776 6.28 11.522 6.85727C10.3941 8.02909 8.85413 9.09091 7 9.09091C5.145 9.09091 3.60588 8.02909 2.478 6.85727C1.93519 6.29028 1.44946 5.66807 1.02637 5Z" />
                                                        <path
                                                            d="M7 2.72721C6.41984 2.72721 5.86344 2.96665 5.4532 3.39287C5.04297 3.81909 4.8125 4.39717 4.8125 4.99993C4.8125 5.6027 5.04297 6.18078 5.4532 6.60699C5.86344 7.03321 6.41984 7.27266 7 7.27266C7.58016 7.27266 8.13656 7.03321 8.5468 6.60699C8.95703 6.18078 9.1875 5.6027 9.1875 4.99993C9.1875 4.39717 8.95703 3.81909 8.5468 3.39287C8.13656 2.96665 7.58016 2.72721 7 2.72721ZM3.9375 4.99993C3.9375 4.15606 4.26016 3.34676 4.83449 2.75005C5.40882 2.15334 6.18777 1.81812 7 1.81812C7.81223 1.81812 8.59118 2.15334 9.16551 2.75005C9.73984 3.34676 10.0625 4.15606 10.0625 4.99993C10.0625 5.8438 9.73984 6.65311 9.16551 7.24982C8.59118 7.84652 7.81223 8.18175 7 8.18175C6.18777 8.18175 5.40882 7.84652 4.83449 7.24982C4.26016 6.65311 3.9375 5.8438 3.9375 4.99993Z" />
                                                    </svg>
                                                </router-link>
                                            </li>
                                            <li>
                                                <span class="pin" @click="updateJobDetail(job.job_key, job.job_slug)">
                                                    <svg width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M11.8798 1.19201C11.9563 1.26957 11.9993 1.37462 11.9993 1.48414C11.9993 1.59365 11.9563 1.6987 11.8798 1.77627L11.0253 2.64024L9.3868 0.98512L10.2413 0.121149C10.3181 0.0435774 10.4223 0 10.5309 0C10.6395 0 10.7437 0.0435774 10.8205 0.121149L11.8798 1.19118V1.19201ZM10.4461 3.22449L8.8076 1.56938L3.22607 7.20836C3.18098 7.2539 3.14704 7.30944 3.12694 7.37056L2.46745 9.36829C2.45549 9.40471 2.45379 9.44377 2.46254 9.48111C2.4713 9.51844 2.49016 9.55259 2.51702 9.57972C2.54388 9.60685 2.57768 9.62591 2.61464 9.63475C2.65161 9.64359 2.69028 9.64188 2.72633 9.62979L4.70399 8.96361C4.76442 8.94355 4.8194 8.90955 4.86456 8.8643L10.4461 3.22532V3.22449Z"></path>
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 10.7585C0 11.0878 0.12947 11.4035 0.359928 11.6363C0.590385 11.8691 0.902953 11.9999 1.22887 11.9999H10.2406C10.5665 11.9999 10.8791 11.8691 11.1095 11.6363C11.34 11.4035 11.4694 11.0878 11.4694 10.7585V5.79319C11.4694 5.68345 11.4263 5.5782 11.3495 5.50061C11.2727 5.42301 11.1685 5.37941 11.0598 5.37941C10.9512 5.37941 10.847 5.42301 10.7702 5.50061C10.6934 5.5782 10.6502 5.68345 10.6502 5.79319V10.7585C10.6502 10.8683 10.607 10.9735 10.5302 11.0511C10.4534 11.1287 10.3492 11.1723 10.2406 11.1723H1.22887C1.12023 11.1723 1.01604 11.1287 0.939222 11.0511C0.862403 10.9735 0.819246 10.8683 0.819246 10.7585V1.6554C0.819246 1.54566 0.862403 1.44041 0.939222 1.36281C1.01604 1.28522 1.12023 1.24162 1.22887 1.24162H6.55397C6.66261 1.24162 6.7668 1.19803 6.84362 1.12043C6.92044 1.04283 6.96359 0.937583 6.96359 0.827842C6.96359 0.718101 6.92044 0.612854 6.84362 0.535256C6.7668 0.457657 6.66261 0.414063 6.55397 0.414062H1.22887C0.902953 0.414063 0.590385 0.544846 0.359928 0.777642C0.12947 1.01044 0 1.32618 0 1.6554V10.7585Z"></path>
                                                    </svg>
                                                </span>
                                            </li>
                                            <li>
                                                <span class="pin" @click="deleteJob(job)">
                                                    <svg width="25" height="25" viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg"><path d="M20 18h2v16h-2z"/><path d="M24 18h2v16h-2z"/><path d="M28 18h2v16h-2z"/><path d="M12 12h26v2H12z"/><path d="M30 12h-2v-1c0-.6-.4-1-1-1h-4c-.6 0-1 .4-1 1v1h-2v-1c0-1.7 1.3-3 3-3h4c1.7 0 3 1.3 3 3v1z"/><path d="M31 40H19c-1.6 0-3-1.3-3.2-2.9l-1.8-24 2-.2 1.8 24c0 .6.6 1.1 1.2 1.1h12c.6 0 1.1-.5 1.2-1.1l1.8-24 2 .2-1.8 24C34 38.7 32.6 40 31 40z"/></svg>
                                                </span>
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="pagination-table-info">
                            <Paginator v-model:first="currentPage" :rows="rowsPerPage" :totalRecords="totalPages" @page="handlePageChange">
                                </Paginator>
                        </div>

                        <div v-for="(item, index) in items" :key="index" class="switch-container">
      <p>{{ item.label }}</p>
      <InputSwitch v-model="item.value" @click="toggleSwitch(index)" />
    </div>
  
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- ========== Dashboard Area end============= -->
</template>
<style scoped>
.pin {
    cursor: pointer;
}
</style>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { toast } from 'vue3-toastify';
import JobList from './CompanyJobList.vue'; // @ is an alias to /src
import CompanyMenu from './CompanyMenu.vue';
import { mapGetters } from 'vuex';
import ProgressSpinner from 'primevue/progressspinner';
import Paginator from 'primevue/paginator';
import axios from 'axios';
import Calendar from 'primevue/calendar';
import { apiUrl, adminDashboardUrl, adminDashboardDomain } from '../../utils/config';
import moment from 'moment';
import InputSwitch from 'primevue/inputswitch';
import ConfirmPopup  from "primevue/confirmpopup";
import router from '@/router';


@Options({
  components: {
    JobList,
    'company-menu': CompanyMenu,
    ProgressSpinner,
    Paginator,
    Calendar,
    InputSwitch,
    ConfirmPopup
  },
  data() {
    return {
      jobs: [],
      user: null,
      isLoading: false,
      currentPage : 1,
      totalPages : 0,
      rowsPerPage : 10,
      search_job_title:null,
      showAlert: false,
      JobsDate:null,
      shortlistConfirm: false,
      permission: null,

    }
  },
  computed: {
    ...mapGetters([
        'currentUser',
        'company',
        'companyJobs'
    ]),     
  },
  methods: 
  {
    handleSwitchChange(job:any) {
      if (job.jobStatus)
        {
            this.$store.dispatch('updateJobStatus', { jobId: job.id, jobTitle:job.job_title, jobStatus: 'active' });
        } 
        else 
        {
            this.$store.dispatch('updateJobStatus', { jobId: job.id, jobTitle:job.job_title, jobStatus: 'inactive' });
        }
    },
    searchByTitle()
        {
            // if(!this.search_job_title)
            //     {
            //         console.log(this.search_job_title);
            //         this.showAlert = true;
            //     }
            //     else
            //     {
            //         this.showAlert = false;
            //         this.isLoading = true; // Show loader
            //         const pageId = 0;
            //         const searchJobTitle = this.search_job_title;

            //         this.fetchListing(pageId, searchJobTitle);
            //     }
            this.isLoading = true; // Show loader
            const pageId = 0;
            const searchJobTitle = this.search_job_title;
            const searchJobDate = null;

            this.fetchListing(pageId, searchJobTitle, searchJobDate);

        },
    searchByDate(date:any)
        {
            console.log(moment(date).format('YYYY-MM-DD'));
            const pageId = 0;
            this.search_job_title = '';
            const searchJobTitle = null;
            const searchJobDate = moment(date).format('YYYY-MM-DD');
            this.fetchListing(pageId, searchJobTitle, searchJobDate);
        },     
    handlePageChange(event:any) 
        {
            this.isLoading = true; // Show loader
            const searchJobTitle = this.search_job_title;
            const searchJobDate = 'null';
            const pageId = event.page;

            this.fetchListing(pageId, searchJobTitle, searchJobDate);
        },
        
    fetchListing( pageId:any, searchJobTitle:any, searchJobDate:any)
        {
            
            axios.get(apiUrl + 'getCompanyJobs?pageId=' + pageId + '&keyword=' + searchJobTitle + '&date=' + searchJobDate, {
            // headers: {
            //     'authorization': 'Bearer ' + localStorage.getItem('token')
            //     } 
            })
            .then(res => {
                console.log(res);
                this.jobs = res.data.Listing; // Store fetched data
                this.totalPages = res.data.count;

                const element = window.document.getElementById('scrollTarget');
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' });
                }
                
            })
            .catch(err => {

            })
            .finally(() => {
            this.isLoading = false; // Hide loader
            

            });

        },
    getJobDetail(job_key:any, job_slug:any) {    
        return {
                path: '/job-details/'+job_key+'/'+job_slug
        };
    },
    updateJobDetail(job_key:any, job_slug:any) {
        if (this.permission && this.permission.allow_edits == 'yes') {
            router.push('/company/update-job/' + job_key + '/' + job_slug)
        } else {
            toast.error('Please purchase or upgrade plan', {
                position: toast.POSITION.BOTTOM_RIGHT,
            })
        }   
    }, 
    deleteJob(job : any) {
        console.log(job);
        this.$store.dispatch('deleteJob', job);
    }, 
  },  
  async mounted() {
    this.user = JSON.parse(this.currentUser)[0];
    this.$store.dispatch('getCompany', this.user.id);
    this.$store.dispatch('getCompanyJobs', '');
    this.permission = this.user.sub_accesses[0] ?? null;
    let Script = document.createElement("script");
    Script.setAttribute("src", "/assets/js/main.js");
    document.head.appendChild(Script);
  },
  watch: {
    companyJobs() {
          this.jobs = this.companyJobs.Listing;
          this.totalPages =  this.companyJobs.count;
      }
  }
})
export default class CompanyJobList extends Vue {}
</script>
