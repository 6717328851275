// export const  apiUrl = 'http://127.0.0.1:8000/api/';
// export const adminDashboardUrl = 'http://127.0.0.1:8000/loginWithEmail/';
// export const adminDashboardDomain = 'http://127.0.0.1:8000/';

export const apiUrl = 'https://wpages.aqtdemos.com/api/';
export const adminDashboardUrl = 'https://wpages.aqtdemos.com/loginWithEmail/';
export const adminDashboardDomain = 'https://wpages.aqtdemos.com/';

// if (location.hostname === "localhost")
//     alert("It's a local server!");

export const ListRecordsNo = 10;